@tailwind base;
@tailwind components;
@tailwind utilities;
@import "react-toastify/dist/ReactToastify.css";

html {
  @apply font-default text-16 text-191c20;
}

html,
body,
#root {
  @apply h-fill;
}

:root {
  --toastify-color-success: #4940e0;
  --toastify-icon-color-success: #4940e0;
}

.ps-contract-title {
  @apply !text-858494 !font-medium !mb-3;
}

.ps-contract-options {
  @apply !rounded-tr-xl !rounded-tl-xl !border-none !bg-f4f7ff;
}

.ps-contract-body {
  @apply !border-y-0 !border-x-[2px] !border-f4f7ff;
}

.ps-checkbox-container {
  @apply !border-none !bg-f4f7ff;
}

.ps-download-link {
  @apply !text-14 !text-3461fd !font-medium;
}

/* NOTE: fix input with on ios safari */
input[type="month"] {
  -webkit-appearance: none;
  -moz-appearance: none;
}
